import * as React from "react";

import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "./views/Home";
import NotFound from "./views/NotFound";
import { useWeb3Context } from "./hooks";
import Layout from "./layout";


import Admin from "./views/Admin";
// import OwnerMint from "./views/Mint/OwnerMint";
import Mint0 from "./views/Mint/Mint0";
import Mint1 from "./views/Mint/Mint1";
import Mint2 from "./views/Mint/Mint2";
import Mint3 from "./views/Mint/Mint3";
import Mint4 from "./views/Mint/Mint4";

import { useEffect, useState, useCallback } from "react";
// import AdminSecond from "./views/AdminSecond";
// import AdminThird from "./views/AdminThird";
// import AdminGeneral from "./views/AdminGeneral";
// import { Background } from "./components/Background";


export const App = () => {
  const { connect, provider, hasCachedProvider, chainID, connected } =
    useWeb3Context();
  const [walletChecked, setWalletChecked] = useState(false);

  useEffect(() => {
    if (hasCachedProvider()) {
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // setWalletChecked(true);
      setWalletChecked(false);
    }
  }, []);
  return (
    <>
      <ToastContainer />
      {/* <Background>
        <FirstSale />
      </Background> */}
      <>
      <>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Admin />} />

            {/* <Route path="/owner" element={<OwnerMint />} /> */}
            <Route path="/mint0" element={<Mint0 />} />
            <Route path="/mint1" element={<Mint1 />} />
            <Route path="/mint2" element={<Mint2 />} />
            <Route path="/mint3" element={<Mint3 />} />
            <Route path="/mint4" element={<Mint4 />} />
            {/* <Route path="/admin/first" element={<AdminFirst />} />
            <Route path="/admin/second" element={<AdminSecond />} />
            <Route path="/admin/third" element={<AdminThird />} /> */}
            {/* <Route path="/mint" element={<Background />}>
              <Route path="first" element={<FirstSale />} />
              <Route path="second" element={<SecondSale />} />
              <Route path="third" element={<ThirdSale />} />
            </Route> */}
            {/* <Route path="/admin/general" element={<AdminGeneral />} /> */}
            <Route path="/*" element={<NotFound />} />
            </Routes> 
        </Layout>
      </> 
        
      </>
    </>
  );
};

export default App;
