import { Box } from "@chakra-ui/react";
import * as React from "react";

import { useNavigate } from "react-router-dom";

import { TheSFTAddress } from "../constants/addresses";
import {   getSFTContract } from "../helpers/contracts";
import { TransactionToastMessage } from "../components/TransactionToastMessage";

import { useWeb3Context } from "../hooks";
import { useEffect, useState } from "react";
import {
  DEFAULT_NETWORK,
  NETWORKS,
  DEFAULT_CHAIN_ID,
  USER_SELECTABLE_NETWORKS,
} from "../constants";
import { Button } from "@chakra-ui/react";
import { toast } from "react-toastify";
import { Input } from "@chakra-ui/react";

import { BigNumber } from "ethers";
import { useFormik } from "formik";

const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

const displayToast = async (msg: string, hash: string) => {
  toast(TransactionToastMessage(msg, hash));
};

export const Admin = () => {
  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    chainID,
    address,
    provider,
    checkWrongNetwork,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  // const mintLoading = useSelector<IReduxState, boolean>(
  //   // (state) => state.jump.loading
  //   (state) => state.mint.loading
  // );
  // const newAddress = useSelector<IReduxState, string>(
  //   (state) => state.mint.newAddress
  // );
  // const newSymbol = useSelector<IReduxState, string>(
  //   (state) => state.mint.newSymbol
  // );

  // const dispatch = useDispatch();

  // const [merkle, setMerkle] = useState([]);
  const [merkleRootWL, setMerkleRootWL] = useState("");
  const [merkleRootSP, setMerkleRootSP] = useState("");
  const [currentMerkleRootWL, setCurrentMerkleRootWL] = useState("");
  const [currentMerkleRootSP, setCurrentMerkleRootSP] = useState("");
  const [secondSaleStart, setSecondSaleStart] = useState(false);
  const [thirdSaleStart, setThirdSaleStart] = useState(false);
  const [firstSaleStart, setFirstSaleStart] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [owner, setOwner] = useState("");
  const [loading, setLoading] = useState(true);
  // const [totalSupply, setTotalSupply] = useState("");
  const [latestTx, setLatestTx] = useState("");
  const [unrevealedTokenUri, setUnrevealedTokenUri] = useState("");
  // const [baseUri, setBaseURI] = useState("");
  // const getMerkleRootData = () => {
  //   console.log("getMerkleRootData");
  //   fetch("https://metacamelot.sxg.workers.dev/rootHash")
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         console.log("rootHash result : ", result);
  //         setMerkleRootWL(result.rootHashWL);
  //         setMerkleRootSP(result.rootHashSP);
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     )
  //     .catch((error) => {
  //       console.error("failed to connect ", error);
  //     });
  // };

  
  useEffect(() => {
  //   getMerkleRootData();
  //   //
    const con = getSFTContract(provider.getSigner());
  //   con.firstSaleStart().then((ret: boolean) => {
  //     setFirstSaleStart(ret);
  //   });

  //   con.secondSaleStart().then((ret: boolean) => {
  //     setSecondSaleStart(ret);
  //   });
  //   con.thirdSaleStart().then((ret: boolean) => {
  //     setThirdSaleStart(ret);
  //   });
  //   con.totalSupply().then((totalSupply_: BigNumber) => {
  //     console.log("totalSupply: ", totalSupply_);
  //     setTotalSupply(totalSupply_.toString());
  //   });

  //   // con._baseTokenURI().then((baseurl: string) => {
  //   //   console.log("baseurl: ", baseurl);
  //   //   setHidden(totalSupply_.toString());
  //   // });
  //   con.unrevealedTokenUri().then((uri: string) => {
  //     setUnrevealedTokenUri(uri);
  //   });
    // con.baseURI().then((uri: string) => {
    //   setBaseURI(uri);
    // });
    // con.totalSupply().then((totalSupply: any) => {
    //   console.log("totalSupply: ", totalSupply);
    // });

  //   con.revealed().then((ret: boolean) => {
  //     setRevealed(ret);
  //   });
    // con.owner().then((ret: string) => {
    //   // setOwner("ret");
    // });

  //   con.merkleRootSP().then((ret: string) => {
  //     setCurrentMerkleRootSP(ret);
  //   });
  //   con.merkleRootWL().then((ret: string) => {
  //     setCurrentMerkleRootWL(ret);
  //   });

    setLoading(false);
  }, [latestTx]);

  // const disableFirst = async () => {
  //   console.log("disableFirst");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("disableFirst1");
  //   try {
  //     const tx = await con.switchFirstSale(false);
  //     displayToast("transaction start", tx.hash);
  //     await tx.wait();
  
  //     console.log("disableFirst2");
  //     displayToast("transaction end", tx.hash);
  
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }
  // };

  // const enableFirst = async () => {
  //   console.log("enableFirst");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("enableFirst1");
  //   try {
  //     const tx = await con.switchFirstSale(true);
  //     displayToast("transaction start", tx.hash);
  
  //     await tx.wait();
  //     console.log("enableFirst2");
  //     displayToast("transaction end", tx.hash);
  
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }

  // };

  // const enableSecondSale = async () => {
  //   console.log("enableSecondSale");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("enableSecondSale1");
  //   try {
  //     const tx = await con.switchSecondSale(true);
  //     displayToast("transaction start", tx.hash);
  
  //     await tx.wait();
  //     console.log("enableSecondSale2");
  
  //     displayToast("transaction end", tx.hash);
  
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }
  // };

  // const disableSecondSale = async () => {
  //   console.log("disableSecondSale");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("disableSecondSale1");
  //   try {
  //     const tx = await con.switchSecondSale(false);
  //     displayToast("transaction start", tx.hash);
  
  //     await tx.wait();
  //     console.log("disableSecondSale2");
  //     displayToast("transaction end", tx.hash);
  
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }
  // };

  // const enableThirdSale = async () => {
  //   console.log("enableThirdSale");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("enableThirdSale1");
  //   try {
  //     const tx = await con.switchThirdSale(true);
  //     displayToast("transaction start", tx.hash);
  
  //     await tx.wait();
  //     console.log("enableThirdSale2");
  
  //     await tx.wait();
  //     displayToast("transaction end", tx.hash);
  
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }
  // };
  // const disableThirdSale = async () => {
  //   console.log("disableThirdSale");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("disableThirdSale1");
  //   try {
  //     const tx = await con.switchThirdSale(false);
  //     displayToast("transaction start", tx.hash);
  
  //     await tx.wait();
  //     console.log("disableThirdSale2");
  //     displayToast("transaction end", tx.hash);
  
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }
  // };
  // const onMerkleRoot = async () => {
  //   console.log("setMerkleRootSPWL");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("setMerkleRootSPWL1");
  //   console.log("merkleRootSP: ", merkleRootSP);
  //   console.log("merkleRootWL: ", merkleRootWL);
  //   try {
  //     const tx = await con.setMerkleRootSPWL(merkleRootSP, merkleRootWL);
  //     displayToast("transaction start", tx.hash);
  
  //     await tx.wait();
  //     console.log("setMerkleRootSPWL2");
  //     displayToast("transaction end", tx.hash);
  
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }
  // };

  const formikSetBaseURI = useFormik({
    initialValues: {
      // description: "",
      // symbol: "",
      // title: "",
      base_uri: "",
    },

    onSubmit: async (values) => {
      console.log("formikSetBaseURI.onSubmit");
      console.log("values: ", values);
      console.log("values.base_uri: ", values.base_uri);

      const con = getSFTContract(provider.getSigner());
      try {
        const tx = await con.setBaseURI(values.base_uri);
        displayToast("transaction start", tx.hash);
  
        await tx.wait();
        displayToast("transaction end", tx.hash);
  
        console.log("onWithdrawMoney");
        setLatestTx(tx.hash);
      } catch (err: any) {
        displayToast("error: ", err.reason);
      }
      // const quantity = 1;
    },
  });

  // const formikSetHiddenBaseURI = useFormik({
  //   initialValues: {
  //     // description: "",
  //     // symbol: "",
  //     // title: "",
  //     hidden_base_uri: "",
  //   },

  //   onSubmit: async (values) => {
  //     console.log("formikSetHiddenBaseURI.onSubmit");
  //     console.log("values: ", values);
  //     console.log("values.hidden_base_uri: ", values.hidden_base_uri);

  //     const con = getSFTContract(provider.getSigner());
  //     try {
  //       const tx = await con.setHiddenBaseURI(values.hidden_base_uri);

  //       displayToast("transaction start", tx.hash);
  
  //       await tx.wait();
  //       displayToast("transaction end", tx.hash);
  
  //       console.log("onWithdrawMoney");
  //       setLatestTx(tx.hash);
  //     } catch (err: any) {
  //       displayToast("error: ", err.reason);
  //     }


  //     // const quantity = 1;
  //   },
  // });

  // const onWithdrawMoney = async () => {
  //   console.log("onWithdrawMoney");
  //   const con = getSFTContract(provider.getSigner());
  //   console.log("onWithdrawMoney");
  //   try {
  //     const tx = await con.withdrawMoney();
  //     displayToast("transaction start", tx.hash);
  
  //     await tx.wait();
  //     displayToast("transaction end", tx.hash);
  
  //     console.log("onWithdrawMoney");
  //     setLatestTx(tx.hash);
  //   } catch (err: any) {
  //     displayToast("error: ", err.reason);
  //   }
  // };

  // useEffect(() => {
  //   // console.log("newSymbol::::::", newSymbol);
  //   // console.log("newAddress::::::", newAddress);
  //   if (newSymbol != "") {
  //     dispatch(clearNewCreatedMint());
  //     navigate("/projects/" + newSymbol);
  //   }
  // }, [newAddress, newSymbol]);
  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);
  if (!isConnected) {
    return (
      <>
        <Button onClick={connect} colorScheme="blue">
          connect
        </Button>
      </>
    );
  }

  if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
    return (
      <>
        <Button onClick={checkWrongNetwork} colorScheme="red">
          switch network
        </Button>
      </>
    );
  }
  if (loading == true) {
    return <>loading</>;
  }

  // const setHiddenBaseURI = () => {
  //   console.log("setHiddenBaseURI");
  //   // console.log("hiddenImageURLValue: ", hiddenImageURLValue);
  // };

  const reveal = async () => {
    console.log("reveal ");
    const con = getSFTContract(provider.getSigner());
    const tx = await con.setReveal(true);
    //トランザクション走り出し。
    displayToast("transaction start", tx.hash);
    await tx.wait();
    //トランザクション完了
    displayToast("transaction end", tx.hash);
    //最終finalityしたトランザクションハッシュを格納。
    setLatestTx(tx.hash);
  };
  const unreveal = async () => {
    console.log("unreveal ");
    const con = getSFTContract(provider.getSigner());
    const tx = await con.setReveal(false);
    //トランザクション走り出し。
    displayToast("transaction start", tx.hash);
    await tx.wait();
    //トランザクション完了
    displayToast("transaction end", tx.hash);
    //最終finalityしたトランザクションハッシュを格納。
    setLatestTx(tx.hash);
  };

  // // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [hiddenImageURLValue, setHiddenImageURLValue] = useState("");
  // // const [isConnected, setConnected] = useState(connected);

  // const onHiddenImageURLChange = (e: any): void => {
  //   // console.log("onHiddenImageURLChange: e=", e);
  //   console.log("onHiddenImageURLChange");
  //   // console.log(e.input);
  // };
  return (
    <>
      
      <hr />
      <br />
      {/* totalSupply={totalSupply} */}
      <br />
      
      
      <br />
      {/* unrevealedTokenUri={unrevealedTokenUri}
      <form onSubmit={formikSetHiddenBaseURI.handleSubmit}>
        <Input
          placeholder="Unrevealed Token URI"
          size="lg"
          name="hidden_base_uri"
          onChange={formikSetHiddenBaseURI.handleChange}
          value={formikSetHiddenBaseURI.values.hidden_base_uri}
        />
        <Button
          mt={4}
          colorScheme="teal"
          type="submit"
          // onClick={setHiddenBaseURI}
        >
          setHiddenBaseURI
        </Button>
      </form> */}
      <br />
      <br />
      <br />
      <form onSubmit={formikSetBaseURI.handleSubmit}>
        <Input
          placeholder="Base URL"
          size="lg"
          name="base_uri"
          onChange={formikSetBaseURI.handleChange}
          value={formikSetBaseURI.values.base_uri}
        />
        <Button
          mt={4}
          colorScheme="teal"
          type="submit"
          // onChange={formikSetBaseURI.handleChange}
          // value={formikSetBaseURI.values.base_uri}
        >
          setBaseURI
        </Button>
      </form>
      <br />
      <br />
      {/* revealed = {revealed ? <>true</> : <>false</>}
      <br />
      <Button mt={4} colorScheme="teal" type="submit" onClick={reveal}>
        reveal
      </Button>
      <Button mt={4} colorScheme="teal" type="submit" onClick={unreveal}>
        unreveal
      </Button>
      <br />
      <hr /> */}
      <br />
      <br />
      <br />
      {/* <a
        href="https://metacamelot.sxg.workers.dev/rootHash"
        target="_blank"
        rel="noreferrer"
      >
        ホワイトリスト: https://metacamelot.sxg.workers.dev/rootHash
      </a> */}
      <br />
      <br />
      {/* <br />
      currentMerkleRootSP={currentMerkleRootSP}
      <br />
      currentMerkleRootWL={currentMerkleRootWL}
      <br />
      {scannerURL}/address/{TheSFTAddress}
      <hr />
      <a
        target="_blank"
        href="https://metacamelot.sxg.workers.dev/rootHash"
        rel="noreferrer"
      >
        ホワイトリスト確認
      </a> */}
    </>
  );
};
export default Admin;
