import {
  Box,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import * as React from "react";

import { useNavigate } from "react-router-dom";
import { TheSFTAddress } from "../../constants/addresses";
import { getSFTContract } from "../../helpers/contracts";

import { useWeb3Context } from "../../hooks";
import { useEffect, useState } from "react";
import {
  DEFAULT_NETWORK,
  NETWORKS,
  DEFAULT_CHAIN_ID,
  USER_SELECTABLE_NETWORKS,
} from "../../constants";
import { Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { mint } from "../../store/slices/mint-slice";
import { useFormik } from "formik";
import { IReduxState } from "../../store/slices/state.interface";
import { BigNumber } from "ethers";
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

export const Mint = () => {
  // const navigate = useNavigate();
  // console.log("TheSFTAddress: ", TheSFTAddress)

  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    chainID,
    address,
    provider,
    checkWrongNetwork,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const mintLoading = useSelector<IReduxState, boolean>(
    // (state) => state.jump.loading
    (state) => state.mint.loading
  );

  const dispatch = useDispatch();

  const [totalSupply, setTotalSupply] = useState("");
  // const [merkle, setMerkle] = useState([]);

  // const getMerkleData = (addr: string) => {
  //   fetch("https://metacamelot.sxg.workers.dev/address/" + addr)
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         console.log(result);
  //         setMerkle(result);
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     )
  //     .catch((error) => {
  //       console.error("failed to connect ", error);
  //     });
  // };

  // useEffect(() => {
  //   if (address) {
  //     console.log("address:", address);
  //     getMerkleData(address);
  //   }
  // }, [address]);
  useEffect(() => {
    const con = getSFTContract(provider.getSigner());
    const nftID = 3
    con.totalSupply(nftID).then((totalSupply_: BigNumber) => {
      //   console.log("totalSupply: ", totalSupply_);
      setTotalSupply(totalSupply_.toString());
    });
  }, []);

  const formikMint = useFormik({
    initialValues: {
      mintQuantity: "1",
      address: "",
    },

    onSubmit: (values) => {
      const nftID = 3
      console.log("onSubmit");
      console.log("mintQuantity: ", values.mintQuantity);
      const quantity = parseInt(values.mintQuantity);
      const to = values.address
      dispatch(
        //@ts-ignore
        mint({
          quantity,
          to,
          nftID: nftID,
          networkID: chainID,
          provider,
        })
      );
    },
  });

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);
  if (!isConnected) {
    return (
      <>
        <Button onClick={connect} colorScheme="blue">
          connect
        </Button>
      </>
    );
  }
  if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
    return (
      <>
        <Button onClick={checkWrongNetwork} colorScheme="red">
          switch network
        </Button>
      </>
    );
  }

  return (
    <>
      {totalSupply} / 100
      <form onSubmit={formikMint.handleSubmit}>
      Amount: <br />
        <NumberInput defaultValue={1} min={1}>
          <NumberInputField
            name="mintQuantity"
            onChange={formikMint.handleChange}
            value={formikMint.values.mintQuantity}
          />
          
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        Address: <br />
        <Input
          placeholder="address"
          size="lg"
          name="address"
          onChange={formikMint.handleChange}
          value={formikMint.values.address}
        />

        <Button mt={4} colorScheme="teal" isLoading={mintLoading} type="submit">
           Mint
        </Button>
      </form>
      <hr />
      
      <br />
      <br />
      <hr />
      <br /> {scannerURL}address/{TheSFTAddress}
      <hr />
    </>
  );
};
export default Mint;
