import { ethers, constants } from "ethers";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
// import { useSnackbar } from "notistack";
import { toast } from "react-toastify";
import { NETWORKS } from "../../constants/blockchain";
import { DEFAULT_CHAIN_ID } from "../../constants/blockchain";

import {
  JsonRpcProvider,
  StaticJsonRpcProvider,
} from "@ethersproject/providers";
// const { enqueueSnackbar, closeSnackbar } = useSnackbar();

// import { success, error, warning } from "./messages-slice.ts_";

import { Networks } from "../../constants/blockchain";
import { messages } from "../../constants/messages";
import { BigNumber } from "ethers";
import { RootState } from "../store";
import { getSFTContract } from "../../helpers/contracts";
import { TransactionToastMessage } from "../../components/TransactionToastMessage";
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

interface IOwnerMint {
  quantity: number;
  to: string;
  nftID: number;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
}

const displayToast = async (msg: string, hash: string) => {
  toast(TransactionToastMessage(msg, hash));
};
export const mint = createAsyncThunk(
  "mint/mint",
  async ({ quantity, to, nftID, networkID, provider }: IOwnerMint, { dispatch }) => {
    console.log("mint/mint");

    const con = getSFTContract(provider.getSigner());

    let mintTx;
    // let newAddress;
    // let newSymbol;
    console.log("to: ", to)
    console.log("nftID: ", nftID)
    console.log("quantity: ", quantity)
    try {
      console.log("nftID: ", nftID)
      console.log("nftID: ", nftID)
      console.log("nftID: ", nftID)
      mintTx = await con.mint(to, nftID, quantity, "0x0000000000000000000000000000000000000000000000000000000000000000");
      // TODO: better to show toast notification.
      console.log("start mintTx.wait ");
      // const id = toast.loading("Sending transaction...");
      displayToast("Sending transaction...", mintTx.hash);


      console.log("mintTx.hash: ", mintTx.hash);
      try {
        await mintTx.wait();
        console.log("finish mintTx.wait ");
        console.log("mintTx.hash: ", mintTx.hash);
        displayToast("Minted successfully...", mintTx.hash);
        // toast.update(id, {
        //   render: "Minted successfully",
        //   type: "success",
        //   autoClose: 5000,
        //   isLoading: false,
        // });
      } catch (err: any) {
        console.log("err:", err);
        toast(err.reason);
        // eslint-disable-next-line no-throw-literal

        // toast.update(id, {
        //   render: "All is good",
        //   type: "error",
        //   isLoading: false,
        // });
        //   return metamaskErrorWrap(err, dispatch);
      } finally {
        console.log("finally");
        if (mintTx) {
          console.log("mintTx: ", mintTx);
          // dispatch(clearPendingTxn(bondTx.hash));
        }
      }

      // TODO: better to show toast notification again.

      // dispatch(success({ text: messages.tx_successfully_updated }));
    } catch (err: any) {
      console.log("err:", err);
      displayToast("error: ", err.reason);
      //   return metamaskErrorWrap(err, dispatch);
    } finally {
      console.log("finally");
      if (mintTx) {
        console.log("mintTx: ", mintTx);
        // dispatch(clearPendingTxn(bondTx.hash));
      }
    }

    return {
      // newAddress: newAddress,
      // newSymbol: newSymbol,
    };
  }
);

interface IWhiteListMint {
  account: string;
  quantity: number;
  hexProof: any;
  // merkle: any;
  // value: string;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
}

// MINT処理
export const firstSaleMint = createAsyncThunk(
  "mint/firstSaleMint",
  async (
    // { account, quantity, hexProof, networkID, provider }: IWhiteListMint,
    { account, quantity, hexProof, networkID, provider }: IWhiteListMint,
    { dispatch }
  ) => {
    let result = false;
    console.log("firstSaleMint1");

    const con = getSFTContract(provider.getSigner());

    let mintTx;
    // let newAddress;
    // let newSymbol;
    console.log("firstSaleMint2");

    try {
      // const hexProof = merkle.hexProof[0];
      console.log("firstSaleMint3");
      console.log("firstSaleMint3 quantity=", quantity);
      console.log("con.address: ", con.address);
      console.log("account: ", account);
      console.log("hexProof: ", hexProof);
      console.log("quantity: ", quantity);

      // const _merkleProof = "";
      // mintTx = await con.firstSaleMint(quantity, hexProof, {
      //   value: parseEther("1.0"),
      // });
      const firstSaleMintPrice = await con.firstSaleMintPrice();
      const value = BigNumber.from(firstSaleMintPrice.toString()).mul(
        BigNumber.from(quantity.toString())
      );
      console.log("value: ", value.toString());

      const gas = await con.estimateGas.firstSaleMint(
        quantity,
        account,
        // [hexProof],
        // merkle.hexProof,
        hexProof,
        {
          //   // from: con.address,
          value: value.toString(),
          //   // value: "0.001",
          //   from: account,
          //   value: "1",
          //   // gasLimit: estimateGas,
          //   // value: "1",
        }
      );
      console.log("gas:", gas.toString());

      mintTx = await con.firstSaleMint(
        quantity,
        account,

        // [hexProof],
        // merkle.hexProof,
        hexProof,
        {
          //   // from: con.address,
          value: value.toString(),
          gasLimit: gas,
          //   // value: "0.001",
          //   from: account,
          //   value: "1",
          //   // gasLimit: estimateGas,
          //   // value: "1",
        }
      );
      // const id = toast.loading("Sending transaction...");
      displayToast("Sending transaction...", mintTx.hash);

      // console.log("firstSaleMint4");
      // console.log("start mintTx.wait ");
      await mintTx.wait();
      // console.log("finish mintTx.wait ");
      // // dispatch(success({ text: messages.tx_successfully_send }));

      // toast.update(id, {
      //   render: "Minted successfully",
      //   type: "success",
      //   autoClose: 5000,
      //   isLoading: false,
      // });
      displayToast("Minted successfully...", mintTx.hash);

      result = true;
    } catch (err: any) {
      console.log("err:", err);
      console.log("err:");
      toast(err.reason);
      throw err;
    } finally {
      console.log("finally");
      if (mintTx) {
        console.log("mintTx: ", mintTx);
      }
    }
    return result;
    // return {
    //   // newAddress: newAddress,
    //   // newSymbol: newSymbol,
    // };
  }
);

interface IThirdSaleMint {
  account: string;
  quantity: number;
  hexProof1: any;
  hexProof2: any;
  whitelist: boolean;
  whitelist2: boolean;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
}

export const thirdSaleMint = createAsyncThunk(
  "mint/thirdSaleMint",
  async (
    {
      account,
      quantity,
      hexProof1,
      hexProof2,
      whitelist,
      whitelist2,
      networkID,
      provider,
    }: IThirdSaleMint,
    { dispatch }
  ) => {
    console.log("thirdSaleMint");

    const con = getSFTContract(provider.getSigner());
    console.log("thirdSaleMint2");

    const thirdSaleMintPrice = await con.thirdSaleMintPrice();
    const thirdSaleMintPriceWL = await con.thirdSaleMintPriceWL();
    const thirdSaleMintPriceSP = await con.thirdSaleMintPriceSP();
    let thePrice = thirdSaleMintPrice;
    let hexProof = hexProof1;
    if (whitelist) {
      thePrice = thirdSaleMintPriceWL;
      hexProof = hexProof1;
    }
    if (whitelist2) {
      thePrice = thirdSaleMintPriceSP;
      hexProof = hexProof2;
    }
    console.log("hexProof: ", hexProof);

    console.log("whitelist: ", whitelist);
    console.log("whitelist2: ", whitelist2);
    console.log("secondSaleMintPriceWL: ", thirdSaleMintPriceWL.toString());
    console.log("secondSaleMintPriceSP: ", thirdSaleMintPriceSP.toString());

    console.log("thirdSaleMint3");
    const value = BigNumber.from(thePrice.toString()).mul(
      BigNumber.from(quantity.toString())
    );
    console.log("value: ", value.toString());
    try {
      const gas = await con.estimateGas.thirdSaleMint(
        quantity,
        account,
        hexProof,
        {
          value: value.toString(),
        }
      );
      console.log("gas:", gas.toString());

      let mintTx;
      // let newAddress;
      // let newSymbol;
      try {
        mintTx = await con.thirdSaleMint(quantity, account, hexProof, {
          value: value,
          gasLimit: gas,
        });
        // const id = toast.loading("Sending transaction...</a>");
        displayToast("Sending transaction...", mintTx.hash);

        console.log(mintTx.hash);
        console.log("start mintTx.wait ");
        await mintTx.wait();
        console.log("finish mintTx.wait ");
        displayToast("Minted successfully...", mintTx.hash);

        // toast.update(id, {
        //   render: "Minted successfully",
        //   type: "success",
        //   autoClose: 5000,
        //   isLoading: false,
        // });
        // dispatch(success({ text: messages.tx_successfully_send }));
      } catch (err: any) {
        console.log("err:", err);

        toast(err.reason);
        //   return metamaskErrorWrap(err, dispatch);
      } finally {
        console.log("finally");
        if (mintTx) {
          console.log("mintTx: ", mintTx);
          // dispatch(clearPendingTxn(bondTx.hash));
        }
      }
    } catch (e: any) {
      toast(e.reason);
    }
    return {
      // newAddress: newAddress,
      // newSymbol: newSymbol,
    };
  }
);
interface ISecondSaleMint {
  account: string;
  quantity: number;
  hexProof1: any;
  hexProof2: any;

  whitelist: boolean;
  whitelist2: boolean;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
}

export const secondSaleMint = createAsyncThunk(
  "mint/secondSaleMint",
  async (
    {
      account,
      quantity,
      hexProof1,
      hexProof2,
      whitelist,
      whitelist2,
      networkID,
      provider,
    }: ISecondSaleMint,
    { dispatch }
  ) => {
    console.log("secondSaleMint1");

    const con = getSFTContract(provider.getSigner());
    console.log("secondSaleMint2");
    const secondSaleMintPrice = await con.secondSaleMintPrice();
    let thePrice = secondSaleMintPrice;
    const secondSaleMintPriceWL = await con.secondSaleMintPriceWL();
    const secondSaleMintPriceSP = await con.secondSaleMintPriceSP();
    let hexProof = hexProof1;
    if (whitelist) {
      thePrice = secondSaleMintPriceWL;
      hexProof = hexProof1;
    }
    if (whitelist2) {
      thePrice = secondSaleMintPriceSP;
      hexProof = hexProof2;
    }
    console.log("hexProof: ", hexProof);

    console.log("whitelist: ", whitelist);
    console.log("whitelist2: ", whitelist2);
    console.log("secondSaleMintPriceWL: ", secondSaleMintPriceWL.toString());
    console.log("secondSaleMintPriceSP: ", secondSaleMintPriceSP.toString());

    console.log("secondSaleMint3");
    const value = BigNumber.from(thePrice.toString()).mul(
      BigNumber.from(quantity.toString())
    );
    console.log("value: ", value.toString());

    let mintTx;
    // let newAddress;
    // let newSymbol;
    try {
      const gas = await con.estimateGas.secondSaleMint(
        quantity,
        account,
        hexProof,
        {
          value: value.toString(),
        }
      );
      console.log("value..4 ");
      console.log("gas:", gas.toString());

      mintTx = await con.secondSaleMint(quantity, account, hexProof, {
        value: value.toString(),
      });
      // const id = toast.loading("Sending transaction...");
      displayToast("Sending transaction...", mintTx.hash);

      console.log("start mintTx.wait ");
      await mintTx.wait();
      // toast.update(id, {
      //   render: "Minted successfully",
      //   type: "success",
      //   autoClose: 5000,
      //   isLoading: false,
      // });
      displayToast("Minted successfully...", mintTx.hash);

      console.log("finish mintTx.wait ");
      // dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
      console.log("err:", err);
      toast(err.reason);
      //   return metamaskErrorWrap(err, dispatch);
    } finally {
      console.log("finally");
      if (mintTx) {
        console.log("mintTx: ", mintTx);
        // dispatch(clearPendingTxn(bondTx.hash));
      }
    }

    return {
      // newAddress: newAddress,
      // newSymbol: newSymbol,
    };
  }
);
export interface IMintSlice {
  loading: boolean;
  result: boolean;
  // newAddress: string;
  // newSymbol: string;
  // newTitle: string;
  // newDescription: string;
  [key: string]: any;
}
const initialState: IMintSlice = {
  loading: false,
  result: false,
  //   mintAddress: "",
  // newAddress: "",
  // newSymbol: "",
};

const mintSlice = createSlice({
  name: "mint",
  initialState,
  reducers: {
    // fetchMintSuccess(state, action) {
    //   state[action.payload.bond] = action.payload;
    // },
    // clearNewCreatedMint(state, action: PayloadAction<string>) {
    // clearNewCreatedMint(state) {
    //   // const target = state.find(x => x.txnHash === action.payload);
    //   // if (target) {
    //   //     state.splice(state.indexOf(target), 1);
    //   // }
    //   state.loading = false;
    //   state.newAddress = "";
    //   state.newSymbol = "";
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(mint.pending, (state) => {
        state.loading = true;
        state.result = false;
      })
      .addCase(mint.fulfilled, (state, action) => {
        console.log("action.payload: ", action.payload);
        state.loading = false;
        state.result = true;
      })
      .addCase(mint.rejected, (state, { error }) => {
        state.loading = false;
        state.result = false;
        console.log(error);
      })
      .addCase(firstSaleMint.pending, (state) => {
        state.loading = true;
        state.result = true;
        console.log("addCase");
      })
      .addCase(firstSaleMint.fulfilled, (state, action) => {
        console.log("firstSaleMint.fulfiled. action.payload: ", action.payload);
        state.loading = false;
        console.log("addCase");
        state.result = true;
      })
      .addCase(firstSaleMint.rejected, (state, { error }) => {
        state.loading = false;
        state.result = false;
        console.log(error);
        console.log("addCase");
      })
      .addCase(secondSaleMint.pending, (state) => {
        state.loading = true;
        state.result = true;
        console.log("addCase");
      })
      .addCase(secondSaleMint.fulfilled, (state, action) => {
        console.log(
          "secondSaleMint.fulfiled. action.payload: ",
          action.payload
        );
        state.loading = false;
        state.result = true;
      })
      .addCase(secondSaleMint.rejected, (state, { error }) => {
        state.loading = false;
        state.result = false;
        console.log(error);
      })
      .addCase(thirdSaleMint.pending, (state) => {
        state.loading = true;
        state.result = true;
      })
      .addCase(thirdSaleMint.fulfilled, (state, action) => {
        console.log("thirdSaleMint.fulfiled. action.payload: ", action.payload);
        state.result = true;
        state.loading = false;
      })
      .addCase(thirdSaleMint.rejected, (state, { error }) => {
        state.result = false;
        state.loading = false;
        console.log(error);
      });
  },
});

export default mintSlice.reducer;

// export const { clearNewCreatedMint } = mintSlice.actions;

const baseInfo = (state: RootState) => state.mint;

export const getMintState = createSelector(baseInfo, (mint) => mint);
