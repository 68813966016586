import { DEFAULT_CHAIN_NETWORK_NAME } from "../blockchain";
import { GoerliTheSFTAddress } from "./goerli";
import { FantomtestTheSFTAddress } from "./fantomtest";
import { MumbaiTheSFTAddress } from "./mumbai";
import { FantomTheSFTAddress } from "./fantom";
import { BSCTestTheSFTAddress } from "./bsctest";
import { MainnetTheSFTAddress } from "./mainnet";

let networkname = DEFAULT_CHAIN_NETWORK_NAME;
let theaddress = "";

console.log("networkname: ", networkname)
if (networkname == "fantomtest") {
  theaddress = FantomtestTheSFTAddress;
} else if (networkname == "fantom") {
  theaddress = FantomTheSFTAddress;
// } else if (networkname == "bsctest") {
//   theaddress = BSCTestTheSFTAddress;
} else if (networkname == "goerli") {
  theaddress = GoerliTheSFTAddress;
} else if (networkname == "mainnet") {
  theaddress = MainnetTheSFTAddress;
} else if (networkname == "mumbai") {
  theaddress = MumbaiTheSFTAddress;
}


console.log("theaddress networkname: ", networkname);
console.log("theaddress: ", theaddress);
console.log("theaddress FantomTheSFTAddress: ", FantomTheSFTAddress);
console.log("theaddress FantomtestTheSFTAddress: ", FantomtestTheSFTAddress);
console.log("theaddress BSCTestTheSFTAddress: ", BSCTestTheSFTAddress);

export const TheSFTAddress = theaddress;
